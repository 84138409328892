.ant-layout-content {
    padding: 30px 12.5px;
    background-color: #f0f2f5;
    border-radius: 18px;
    flex: 1 0 auto!important;
}
.ant-layout-header {
    padding: 0 12.5px;
    background-color: #ffffff;
    border-bottom: none;
}

h1.ant-typography, div.ant-typography-h1, div.ant-typography-h1 > textarea, .ant-typography h1 {
    margin-bottom: 0.8em;
    color: rgba(0, 0, 0, 0.70);
    font-weight: 600;
    font-size: 34px;
    text-align: center;
}
h2.ant-typography, div.ant-typography-h2, div.ant-typography-h2 > textarea, .ant-typography h2 {
    margin-bottom: 0.5em;
    font-weight: 600;
    font-size: 17px;
    line-height: 1.35;
    color: #00B0EF;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0 10px;
}

.ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after{
    right: 10px;
    left: 10px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: 16px;
}

.ant-modal-body {
    padding: 12.5px;
}

.ant-modal-header {
    padding: 16px 12.5px;
}

p {
    margin-bottom: 0em;
}

/* Collapse */
.ant-collapse > .ant-collapse-item {
    border-bottom: none;
}

.ant-collapse {
    border: none;
    background-color: white;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
}

.ant-collapse-content > .ant-collapse-content-box {
    padding:12.5px 12.5px;
}

/* End Collapse */

.ant-menu-horizontal{
    border-bottom: none;
}

.ant-layout {
    background-color: #ffffff;
}
.ant-layout-footer {
    padding: 19.44px 10px;
}

.mission-detail-popconfirm {
    width: 100% !important;
}