.home-cards-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin: auto;
    margin-top: 20px;
}

.home-layout-container {
    height: 100vh;
}

.react-pdf__Document {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
}

.react-pdf__Page__canvas {
    border-radius: var(--basic-border-radius);

}
@primary-color: #00B0EF;@btn-primary-color: #ffffff;@link-color: #00B0EF;@success-color: #558b2f;@warning-color: #fbc02d;@error-color: #e53935;@font-size-base: 16px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 18px;@box-shadow-base: none;