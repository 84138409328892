#body {
    --primary-color: #00B0EF;
    --light-primary-color: #00b6f8;
    --box-shadow-primary-color: #00afef27;
    --secondary-color: #3a1e2c;
    /* --background-color: #e4e4e4; */
    --background-color: #eceff1;
    /* --background-color: #102027; */
    --dark-color: #373737;
    --light-color: white;
    --error-color: #ff2626;
    --border-color: #b1b1b1;
    --success-color: #51b709;
    --positive-color: #0949b7;
    --overtime-primary-color: #5d126b;
    --overtime-secondary-color: #801093;
    --undertime-primary-color: #740000;
    --undertime-secondary-color: #b10202;
    /* --box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2); */
    --box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 6px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    /* --box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px; */
    /* --box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px; */
    /* --planning-is-period: #f9caa799; */
    /* --planning-is-period: #c3b9b188; */
    --planning-is-period: #c2c2c288;
    --planning-outside-period: rgba(255, 38, 38, 0.2);
    /* --planning-vacations: #bfd6f640; */
    /* --planning-vacations: #f7f7f780; */
    --planning-vacations: #c5e1a560;
    --planning-user-vacations: #3da4ab99;
    --planning-weekend: white;
    /* --planning-weekend-user: #be9b7b44; */
    /* --planning-weekend-user: #ffd3b680; */
    --planning-weekend-user: #d8d8d880;
    /* --today-primary-color: #dfc2aabe; */
    --today-primary-color: #8d8d8d99;
    --th-today-primary-color: #a8a8a8;
    /* --planning-user-1: #F0D5C0; */
    /* --planning-user-2: #F6DBC6; */
    --planning-user-1: #D2CDC8;
    --planning-user-2: #D9D4CF;
    --planning-outside-working-days: rgba(142, 142, 142, 0.25);
}