
.login-background-image {
    background-image: url('../images/background_barry.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
}

.login-background {
    background-color: rgba(12, 12, 12, 0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}

.login-rights {
    width: 80%;
    padding: 5px 0px;
    text-align: center;
    color: white;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px 5px 0px 0px;
}

.login-parent {
    width: calc(100% - 20px)!important;
    height: fit-content;
    max-width: 400px;
}

.login-content {
    background-color: white;
    /* border-radius: 5px; */
    border-radius: 18px;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    box-shadow: var(--box-shadow);
    padding: 20px;
}

.login-content .ant-input-wrapper {
    display: -webkit-flex;
    display: flex;
}

.login-content .ant-input-group-addon {
    width: 50px;
    padding-top: 2px;
}

.login-header {
    /* background-color: #211c1c; */
    margin: auto;
    height: 80px;
}

.login-logo {
    max-height: 100%;
}

.login-body {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}

.login-input {
    width: 100%
}

.login-submit {
    width: 60%;
    margin-top: 20px;
}

.login-footer-links {
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
}

.login-forgot {
    text-align: center;
    cursor: pointer;
    color: #00b6f8;
    margin: 5px auto 5px auto;
}

.login-forgot:hover {
    color: #16799c;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
}

.login-multiple-user {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: all .1s;
}

.login-multiple-user:hover {
    background: #93d5ec;
}

.login-multiple-user .ant-avatar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00b6f8;
}