.loginsms-background {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url('../images/background_barry.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(30%);
}

.loginsms-container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginsms-card {
    width: calc(100% - 20px);
    max-width: 400px;
    height: fit-content;
    position: relative;
}